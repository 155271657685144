import {BrowserRouter, Routes, Route,Navigate} from 'react-router-dom';
import './App.css'
import ScrollToTop from './configTools/scrollTop';
import PublicLayout from './layout/public'
import PublicLogin from './pages/public/login'
import PublicShopifySuccess from './pages/public/shopifysuccess'
import PublicShopifyFailed from './pages/public/shopifyfailed'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<PublicLayout/>}>
            <Route index element={<PublicLogin/>} />
            <Route path='shopifymessagesuccess' element={<PublicShopifySuccess/>}/>
            <Route path='shopifymessagefailed' element={<PublicShopifyFailed/>}/>
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
