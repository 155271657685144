import React, { useState } from 'react'
import Input from '../../../components/forms/primaryinput'
import Button from '../../../components/forms/primarybutton'
import './index.css'

const Index = () => {
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
  return (
    <div className='public_login_wrapper'>
      <div className='public_login_container'>
        <div className='public_login_title'>
            <h3>Sign in</h3>
        </div>
        <div className='public_login_form'>
            <Input Lable={'Username'} Placeholder={'Enter username'} Type={'text'} Value={username} setValue={setUsername}/>
            <Input Lable={'Password'} Placeholder={'Enter password'} Type={'password'} Value={password} setValue={setPassword}/>
            <Button Value={'Sign in'}/>
        </div>
      </div>
    </div>
  )
}

export default Index
