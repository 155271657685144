import React from 'react'
import './index.css'

const Index = ({Value,Action}) => {
  return (
    <div className='primary_button_container'>
      <button onClick={Action}>{Value}</button>
    </div>
  )
}

export default Index
