import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import './index.css'

const Index = () => {
  return (
    <div className='shopifymessage_wrapper'>
        <div className='shopifymessage_container'>
            <div className='shopifymessage_icon'>
                <FontAwesomeIcon icon={faCheck}/>
            </div>
            <p>App installed Successfully</p>
        </div>
    </div>
  )
}

export default Index
